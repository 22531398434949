/* Custom Fonts */
@font-face{
  font-family: Centra;
  src: url('./assets/font/CentraNo2-Bold.ttf');
  font-weight: 700;
}

@font-face{
  font-family: Centra;
  src: url('./assets/font/CentraNo2-Medium.ttf');
  font-weight: 500;
}

@font-face{
  font-family: Centra;
  src: url('./assets/font/CentraNo2-Book.ttf');
  font-weight: 400;
}

/* Default CSS */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
  scroll-padding-top: 75px;
}

body {
  font-weight: 400;
  overflow-x: hidden;
  position: relative;
  background-color: #121212 !important;
  color: #fff !important;
  font-family: 'Centra', sans-serif !important;
}

h1, h2, h3, h4, h5, h6 {
  margin: 0;
  padding: 0;
  line-height: normal;
}

p, a, li, button, ul {
  margin: 0;
  padding: 0;
  line-height: normal;
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

img {
  width: 100%;
  height: auto;
}

button {
  border: 0;
  background-color: transparent;
}

input:focus, textarea:focus, select:focus {
  outline: none;
}

@media (min-width:1700px) {
	main .container {
		max-width: 100%;
		padding: 0 150px;
	}
}

p.success {
	color: green;
}

p.danger {
	color: red;
}

/************ Navbar Css ************/
nav.navbar {
  padding: 18px 0;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 9999;
  transition:  0.32s ease-in-out;
}
nav.navbar.scrolled {
  padding: 0px 0;
  background-color: #121212;
}
nav.navbar a.navbar-brand {
    width: 9%;
}
nav.navbar .navbar-nav .nav-link.navbar-link {
  font-weight: 400;
  color: #fff !important;
  letter-spacing: 0.8px;
  padding: 0 25px;
  font-size: 18px;
  opacity: 0.75;
}
nav.navbar .navbar-nav a.nav-link.navbar-link:hover,
nav.navbar .navbar-nav a.nav-link.navbar-link.active {
    opacity: 1;
}
span.navbar-text {
    display: flex;
    align-items: center;
}
.social-icon {
    display: inline-block;
    margin-left: 14px;
}
.social-icon a {
    width: 42px;
    height: 42px;
    display: inline-flex;
    border-radius: 50%;
    margin-right: 6px;
    align-items: center;
    justify-content: center;
    line-height: 1;
    border: 1px solid rgba(255, 255, 255, 0.5);
}
.social-icon a::before {
    content: "";
    width: 42px;
    height: 42px;
    position: absolute;
    background-color: #ffffff;
    border-radius: 50%;
    transform: scale(0);
	transition: 0.3s ease-in-out;
}
.social-icon a:hover::before {
    transform: scale(1);
}
.social-icon a img {
	width: 40%;
	z-index: 1;
	transition: 0.3s ease-in-out;
}
.social-icon a:hover img {
	filter: brightness(0) saturate(100%) invert(0%) sepia(7%) saturate(98%) hue-rotate(346deg) brightness(95%) contrast(86%);
}
.navbar-text button {
  font-weight: 700;
  color: #fff;
  border: 1px solid #fff;
  padding: 18px 34px;
  font-size: 18px;
  margin-left: 18px;
	position: relative;
	background-color: transparent;
	transition: 0.3s ease-in-out;
}
.navbar-text button span {
  z-index: 1;
}
.navbar-text button::before {
  content: "";
  width: 0%;
  height: 100%;
  position: absolute;
  background-color: #fff;
  left: 0;
  top: 0;
  z-index: -1;
  transition: 0.3s ease-in-out;
}
.navbar-text button:hover {
  color: #121212;
}
.navbar-text button:hover::before {
	content: "";
	width: 100%;
	height: 100%;
	position: absolute;
}
nav.navbar .navbar-toggler:active,
nav.navbar .navbar-toggler:focus {
	outline: none;
    box-shadow: none;
}
nav.navbar .navbar-toggler-icon {
  width: 24px;
  height: 17px;
  background-image: none;
  position: relative;
  border-bottom: 2px solid #fff;
  transition: all 300ms linear;
  top: -2px;
}
nav.navbar .navbar-toggler-icon:focus {
	border-bottom: 2px solid #fff;
}
nav.navbar .navbar-toggler-icon:after,
nav.navbar .navbar-toggler-icon:before {
	width: 24px;
	position: absolute;
	height: 2px;
	background-color: #fff;
	top: 0;
	left: 0;
	content: '';
	z-index: 2;
	transition: all 300ms linear;
}
nav.navbar .navbar-toggler-icon:after {
	top: 8px;
}
nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:after {
	transform: rotate(45deg);
	background-color: #fff;
	height: 2px;
}
nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:before {
	transform: translateY(8px) rotate(-45deg);
	background-color: #fff;
	height: 2px;
}
nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon {
	border-color: transparent;
}

/************ Banner Css ************/
.banner {
  margin-top: 0;
  padding: 200px 0 200px 0;
  background-image: url('./assets/img/banner-bg.jpg');
  background-position: top center;
  background-size: cover;
  background-repeat: no-repeat;
  /* height: 100vh; */
}
/* .banner .tagline {
  font-weight: 700;
  letter-spacing: 0.8px;
  padding: 8px 10px;
  background: linear-gradient(90.21deg, rgba(1, 2, 128, 0.5) -5.91%, rgba(173, 216, 230, 0.5) 111.58%);
  border: 1px solid rgba(255, 255, 255, 0.5);
  font-size: 20px;
  margin-bottom: 16px;
  display: inline-block;
} */

.banner h1 {
  font-size: 65px;
  font-weight: 700;
  letter-spacing: 0.8px;
  line-height: 1;
  margin-bottom: 20px;
  display: block;
}

.introduction {
  align-content: center;
}

.introduction .text-transition {
  justify-content: center;
}

.banner p {
  color: #B8B8B8;
  font-size: 18px;
  letter-spacing: 0.8px;
  line-height: 1.5em;
  width: 96%;
}
.banner button {
  color: #fff;
  font-weight: 700;
  font-size: 20px;
  letter-spacing: 0.8px;
  display: flex;
  align-items: center;
  padding-top: 20px;
}
.banner button svg {
  font-size: 25px;
  margin-left: 10px;
  transition: 0.3s ease-in-out;
  line-height: 1;
}
.banner button:hover svg {
  margin-left: 25px;
}

.banner .portrait-image {
  border-radius: 50%;
}

.txt-rotate > .wrap {
  border-right: 0.08em solid #666;
}

.button-box {
  margin-top: 50px;
}

.button-box > a {
  text-decoration: none;
}

/************ Education Css ************/
.education {
  padding: 0 0 50px 0;
  padding-top: 80px;
  position: relative;
}

.education-box {
  background: #151515;
  border-radius: 64px;
  text-align: center;
  padding: 60px 50px;
  margin-top: -60px;
}

.education h2{
  font-size: 45px;
  font-weight: 700;
}

.education h3 {
  font-size: 30px;
  font-weight: 500;
  text-align: start;
}

.education p {
  color: #B8B8B8;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0.8px;
  line-height: 1.5em;
  margin: 10px 0 0 0;
  text-align: start;
}

.education-title {
  font-size: 45px;
  font-weight: 500;
}
.education-details {
  display: flex;
  justify-content: space-between;
}

.education-vertical-timeline h2 {
  text-align: left;
  margin: 0 0 -15px 0
}

.education-vertical-timeline p.location {
  color: #000000;
  font-weight: 100;
  text-align: start;
}

.date {
  text-align: justify;
}

/************ Skills Css ************/
.skills {
  padding: 0 0 50px 0;
  padding-top: 80px;
  position: relative;
}
.skills-box {
  background: #151515;
  border-radius: 64px;
  text-align: center;
  padding: 60px 50px;
  margin-top: -60px;
}
.skills h2 {
	font-size: 45px;
	font-weight: 700;
}
.skills p {
    color: #B8B8B8;
    font-size: 18px;
    letter-spacing: 0.8px;
    line-height: 1.5em;
    margin: 0 0 30px 0;
}

.skills-sec {
  justify-content: space-around;
}

.skills-cols dl {
  margin: 0;
}

.skills-cols dt {
  font-weight: 100;
}

.coding, 
.language {
  display: flex;
  justify-content: space-between;
}

/************ Experience Css ************/
.experience-box {
  background: #151515;
  border-radius: 64px;
  text-align: center;
  padding: 60px 50px;
}

/***only inflicting padding on direct children of experience-box***/
.experience-box > h2 {
  padding: 0 0 20px 0;
}

.experience h2 {
  font-size: 45px;
  font-weight: 700;
  margin: 0 0 0 0;
}

.experience p {
  color: #B8B8B8;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0.8px;
  line-height: 1.5em;
  margin: 10px 0 0 0;
  text-align: start;
}

.experience-vertical-timeline h2 {
  text-align: left;
  margin: 0 0 -15px 0
}

.experience-vertical-timeline p.location {
  color: #000000;
  font-weight: 100;
  text-align: start;
}

/************ Certificate Css ************/
.certificate h2{
  font-size: 45px;
  font-weight: 700;
  text-align: start;
}

.certificate-box {
  min-height: 300px;
  align-content: center;
}

.certificate-box img {
  padding: 0 25px 20px 25px;
  width: 23%;
  height: 23%;
}


.carousel-control-prev,
.carousel-control-next {
  display: none !important;
}

.carousel-indicators {
  margin-bottom: 0 !important;
}

/************ Projects Css ************/
.projects {
  padding: 0 0 50px 0;
  padding-top: 80px;
  position: relative;
}

.projects-box {
  background: #151515;
  border-radius: 64px;
  text-align: center;
  padding: 60px 50px;
  margin-top: -60px;
}

.projects h2 {
	font-size: 45px;
	font-weight: 700;
}

.card-container .card {
  text-align: left;
  --bs-card-border-width: none;
}

.card img {
  width: 50%;
  display: block;  
  margin: 0 auto;
  padding-bottom: 30px;
}

.card .card-body {
  display: flex;
  padding-inline: 0;
}

.card .title {
  padding-right: 50px;
}

.arrow-button {
  width: 40px;
}

.arrow-button.disabled {
  opacity: 0.3;
  pointer-events: none; /* Prevents click events */
  cursor: not-allowed; /* Changes cursor to indicate it's not clickable */
}

/* .react-horizontal-scrolling-menu--scroll-container {
  scrollbar-width: none;
} */

/* .projects {
  padding: 0 0 50px 0;
  padding-top: 80px;
  position: relative;
}

.projects-box {
  background: #151515;
  border-radius: 64px;
  text-align: center;
  padding: 60px 50px;
  margin-top: -60px;
}

.projects h2 {
	font-size: 45px;
	font-weight: 700;
}

.scrollable-container {
  overflow-x: auto; 
}

.projects .card {
  background-color: #FFFFFF;
  min-width: 300px;
  max-width: 700px;
  min-height: 400px; 
  max-height: 600px;
  align-items: center;
  margin: 10px 10px;
  padding: 50px 20px;
  border-radius: 20px;
}

.projects .description-sec {
  display: flex;
  padding: 0;
}

.projects .project-desc-title {
  padding: 0;
  width: fit-content;
  padding-right: 50px;
} 

.projects .project-desc {
  padding: 0;
}

.projects .card-img-top {
  width: 70%;
} 

.projects .card-body {
  text-align: start;
  align-self: start;
} */

/************ Contact Css ************/
.contact {
  /* background: linear-gradient(90.21deg, #010280 -5.91%, #ADD8E6 111.58%); */
  background: linear-gradient(-150deg, #000035 80%, #ADD8E6 20%);
  padding: 50px 0 0 0;
  height: 100vh;  /* Ensures this section covers the whole screen */
  align-content: center;
}

.contact-left-col {
  text-align: start;
}

.contact-left-col h2 {
  margin: 0 0 10px 0 !important; 
}
.contact h2 {
	font-size: 45px;
	font-weight: 700;
  margin-bottom: 30px;
}
.contact form input, .contact form textarea {
  width: 100%;
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 20px;
  color: #fff;
  margin: 0 0 8px 0;
  padding: 18px 26px;
  font-weight: 500;
  font-size: 18px;
  letter-spacing: 0.8px;
  transition: 0.3s ease-in-out;
}
.contact form input:focus, .contact form textarea:focus {
  background: rgba(255, 255, 255, 1);
  color: #121212;
}
.contact form input::placeholder, .contact form textarea::placeholder {
  font-size: 16px;
  font-weight: 400;
  color: #fff;
}
.contact form input:focus::placeholder, .contact form textarea:focus::placeholder {
  color: #121212;
  opacity: 0.8;
}
.contact form button {
  font-weight: 700;
  color: #000;
  background-color: #fff;
  padding: 14px 48px;
  font-size: 18px;
  margin-top: 25px;
  border-radius: 0;
  position: relative;
  transition: 0.3s ease-in-out;
}
.contact form button span {
  z-index: 1;
  position: relative;
}
.contact form button:hover {
  color: #fff;
}
.contact form button::before {
  content: "";
  background: #121212;
  width: 0;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  transition: 0.3s ease-in-out;
}
.contact form button:hover::before {
  width: 100%;
}

/************ Footer Css ************/
.footer {
  padding: 0 0 50px 0;
  background-image: url('./assets/img/footer-bg.png');
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}
.newsletter-bx {
  background: #FFFFFF;
  border-radius: 55px;
  color: #121212;
  padding: 85px 125px;
  margin-bottom: 80px;
  margin-top: -122px;
}
.newsletter-bx h3 {
  font-weight: 700;
  letter-spacing: 0.5px;
  line-height: 1.2em;
}
.new-email-bx {
  background: #fff;
  padding: 5px;
  border-radius: 20px;
  position: relative;
  z-index: 0;
  display: flex;
  align-items: center;
}
.new-email-bx::before {
  content: "";
  background: linear-gradient(90.21deg, #AA367C -5.91%, #4A2FBD 111.58%);
  border-radius: 20px;
  position: absolute;
  z-index: -1;
  top: -1px;
  left: -1px;
  bottom: -1px;
  right: -1px;
}
.new-email-bx::after {
  content: "";
  background: #fff;
  border-radius: 20px;
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
.new-email-bx input {
  width: 100%;
  color: #121212;
  font-weight: 500;
  background: transparent;
  border: 0;
  padding: 0 15px;
}
.new-email-bx button {
  background: linear-gradient(90.21deg, #AA367C -5.91%, #4A2FBD 111.58%);
  padding: 20px 65px;
  color: #fff;
  font-weight: 500;
  letter-spacing: 0.5px;
  border-radius: 18px;
}
.footer img {
  width: 26%;
}
.footer p {
  font-weight: 400;
  font-size: 14px;
  color: #B8B8B8;
  letter-spacing: 0.5px;
  margin-top: 20px;
}

/************ Default Css ************/
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}